<script setup>
import Layout from "../../layouts/main.vue";
import { ref, reactive, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import Data from "./dataFormTimeRange";
import MethodService from "../../service/MethodService";
import mushroom from "cem-primary-api";
import Swal from "sweetalert2";
import toastr from "toastr";
import { ListService } from "@/stores/ListService";
const storeListService = ListService();
import { ListTableScreen } from "@/stores/ListTableScreen";
const storeListTableScreen = ListTableScreen();
import { ListUsecase } from "@/stores/ListUsecase";
const storeListUsecase = ListUsecase();
// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
import LanguageService from "../../service/LanguageService";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const tableRules = reactive(MethodService.copyObject(Data.tableRules));
const dataTables = reactive({ value: [] });
const targetType = Data.targetType;
let loading = ref(true);
const pageSizeDefault = ref(10);
const listMenuDynamic = ref([]);
const getListTimeRange = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router
    .replace({
      name: "ListTimeRange",
      query: {
        ...dataFilter,
      },
    })
    .catch(() => {});

  try {
    const response = await mushroom.time_range.listAsync(dataFilter, {
      enabledCache: false,
    });
    if (response.result) {
      dataTables.value = response.result;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  } finally {
    setTimeout(() => {
      loading.value = false;
    }, 1000);
  }
};

// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = async (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  await getListTimeRange();
};
const fn_tableSortChange = async (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  loading.value = true;
  await getListTimeRange();
};

// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
};
// tìm kiếm
const fn_TimKiem = async () => {
  tableRules.filters = MethodService.filterTable(
    JSON.stringify(tableRules.dataSearch)
  );
  tableRules.offset = 0;
  tableRules.sort = "";
  await getListTimeRange();
};

const fn_Add = () => {
  router.push({ name: "TimeRangeAdd" });
};

const fn_handle = async (type, scope, row) => {
  if (type == "view") {
    router.push({
      name: "TimeRangeDetail",
      params: { id: row.id },
    });
  }
  if (type === "update") {
    router.push({
      name: "TimeRangeUpdate",
      params: { id: row.id },
    });
  }
  if (type === "delete") {
    Swal.fire({
      title: t("t_warning"),
      text: t("t_record_delete"),
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: t("swal_accept"),
      cancelButtonText: t("swal_cancel"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseGroup = await mushroom.time_range.deleteAsync({
            id: row.id, // required
          });
          if (responseGroup.result) {
            toastr.success(t("t_record_delete_success"));
            await getListTimeRange();
          }
        } catch (e) {
          console.error("Có lỗi: %o", e.message);
        }
      }
    });
  }
};

const fn_hashUrl = () => {
  MethodService.hashUrl(route.query, tableRules);
  pageSizeDefault.value = tableRules.limit;
  if (route.query.offset && route.query.limit) {
    tableRules.offset = Number(route.query.offset);
    tableRules.page = Number(route.query.offset) / route.query.limit + 1;
  }
};
const getMenuDynamic = async () => {
  try {
    const response = await mushroom.menu.getAllAsync({
      filters: ["target_type=dynamic"],
    });
    listMenuDynamic.value = response.result;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const renderTarget = (type, target_id, link) => {
  let name = "";
  if (type === "service") {
    name = storeListService.data.filter((item) => item.id === target_id)[0]
      .name;
  }
  if (type === "table_screen") {
    name = storeListTableScreen.data.filter((item) => item.id === target_id)[0]
      .name;
  }
  if (type === "usecase") {
    name = storeListUsecase.data.filter((item) => item.id === target_id)[0]
      .name;
  }
  if (type === "dynamic") {
    name = listMenuDynamic.value.filter(
      (item) => item.target_id === target_id
    )[0]?.label;
  }
  if (type === "static") {
    name = link;
  }

  return name;
};
const renderName = (type) => {
  let name = "";
  if (type === "service") {
    name = "Service";
  }
  if (type === "table_screen") {
    name = "Table screen";
  }
  if (type === "usecase") {
    name = "Usecase";
  }
  if (type === "dynamic") {
    name = "Dynamic";
  }
  if (type === "static") {
    name = "Static";
  }
  return name;
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
  }
);

onMounted(async () => {
  fn_hashUrl();
  await storeListService.getAllListService();
  await storeListTableScreen.getAllListTableScreen();
  await storeListUsecase.getAllListUsecase();
  await getMenuDynamic();
  await getListTimeRange();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">Time range</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border me-2"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button class="btn btn-secondary btn-border" @click="fn_Add">
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="top"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="rulesForm"
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-target-type')">
                        <el-select
                          filterable
                          v-model="tableRules.dataSearch.value['target_type']"
                          :placeholder="$t('t-target-type')"
                          clearable
                        >
                          <el-option
                            v-for="item in targetType"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item :label="$t('t_day_limit')">
                        <el-input-number
                          :min="1"
                          :step="1"
                          :step-strictly="true"
                          clearable
                          controls-position="right"
                          style="width: 100%"
                          v-model="tableRules.dataSearch.value['day_limit']"
                          :placeholder="$t('t_day_limit')"
                        ></el-input-number>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem()"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              background
              layout="total, sizes, prev, pager, next, jumper"
            />
          </div>
          <el-table
            size="small"
            style="width: 100%"
            :data="dataTables.value"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop=""
              :label="$t('t-stt')"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="target_type"
              :label="$t('t-target-type')"
              min-width="120"
              align="start"
            >
              <template #default="scope">
                {{ renderName(scope.row.target_type) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="day_limit"
              :label="$t('t_day_limit')"
              min-width="100"
              align="center"
            />
            <el-table-column
              prop="target_id"
              :label="$t('t-target')"
              min-width="150"
              align="start"
            >
              <template #default="scope">
                <span class="link-table">
                  {{
                    renderTarget(
                      scope.row.target_type,
                      scope.row.target_id,
                      scope.row.link
                    )
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('t-action')"
              align="center"
              width="150px"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <!-- <button
                      type="button"
                      class="btn btn-soft-info waves-effect waves-light btn-sm"
                      v-b-popover.hover.left="'Xem'"
                      @click="fn_handle('view', scope.$index, scope.row)"
                    >
                      <i class="ri-eye-line"></i>
                    </button> -->
                  <el-tooltip
                    :content="$t('t-edit')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.$index, scope.row)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('t-delete')"
                    placement="top"
                    :enterable="false"
                    effect="light"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.$index, scope.row)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
/* .el-table {
  max-height: calc(100vh - 236px);
} */
.link-table {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
